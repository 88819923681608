import React from "react";
import ImageComponent from "../common/ImageComponent";
import classes from "./FooterComponent.module.css";
import { NavLink } from "react-router-dom";

const FooterComponent = () => {
  return (
    <div className={classes["footer-container"]}>
      <div className={classes["footer-sub-container"]}>
        <div
          style={{
            // backgroundColor: "red",
            width: "20%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImageComponent
            style={{
              height: "24px",
              maxWidth: "85px",
            }}
            src={"https://truetab.ai/wp-content/uploads/2022/03/logo-dark.png"}
            alt={"logo"}
          />
        </div>
        <span
          className={classes["footer-text"]}
          style={{
            whiteSpace: "nowrap",
            display: "inline",
          }}
        >
          <span
            style={{
              whiteSpace: "pre-wrap",
              display: "inline",
            }}
          >
            © Copyright 2023 Truetab All Rights Reserved |
          </span>
          <NavLink
            to={"/privacy-policy"}
            style={{ color: "white", paddingLeft: 5, paddingRight: 5 }}
          >
            <span
              style={{
                whiteSpace: "pre-wrap",
                display: "inline",
              }}
            >
              {`Privacy Policy`}
            </span>
          </NavLink>
          |
          <NavLink
            to={"/terms-and-conditions"}
            style={{ color: "white", paddingLeft: 5, paddingRight: 5 }}
          >
            <span
              style={{
                whiteSpace: "pre-wrap",
                display: "inline",
              }}
            >
              {"Terms & Conditions"}
            </span>
          </NavLink>
          |
          <NavLink
            to={"/shipping-and-returns-policy"}
            style={{ color: "white", paddingLeft: 5, paddingRight: 5 }}
          >
            <span
              style={{
                whiteSpace: "pre-wrap",
                display: "inline",
              }}
            >
              {"Shipping & Returns Policy"}
            </span>
          </NavLink>
        </span>
      </div>
    </div>
  );
};

export default FooterComponent;
