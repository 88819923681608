import React, { useCallback, useState } from "react";
import ImageComponent from "../common/ImageComponent";
import CustomTextField from "../common/CustomTextField";
import { CalcWidthValue, isValidPhoneNumber } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";
import classes from "./DeleteUserComponent.module.css";
import { displayToast } from "../common/toast/toastUtils";
import { sendRequestToDeletePharamcy } from "../../api/common";

const DeleteUserComponent = () => {
  const { width } = useWindowDimensions();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pharmacy, setPharmacy] = useState("");
  const [message, setMessage] = useState("");

  const ValidationForm = useCallback(() => {
    if (!name?.length) {
      displayToast({
        type: "error",
        title: "Name is Required",
      });
      return false;
    }
    if (!email?.length) {
      displayToast({
        type: "error",
        title: "Email id is Required",
      });
      return false;
    }
    if (!mobileNo?.length) {
      displayToast({
        type: "error",
        title: "Mobile No. is Required",
      });
      return false;
    } else {
      if (!isValidPhoneNumber(mobileNo)) {
        displayToast({
          type: "error",
          title: "Enter Valid Mobile No.",
        });
        return false;
      }
    }
    if (!pharmacy?.length) {
      displayToast({
        type: "error",
        title: "Pharmacy Name is Required",
      });
      return false;
    }
    if (!message?.length) {
      displayToast({
        type: "error",
        title: "Message is Required",
      });
      return false;
    }
    return true;
  }, [
    email?.length,
    message?.length,
    mobileNo,
    name?.length,
    pharmacy?.length,
  ]);

  const onSubmitHandler = useCallback(async () => {
    // end-point -> /delete-pharmacy-request/
    // method -> post
    if (ValidationForm()) {
      const payload = {
        name: name,
        email: email,
        mobile_no: mobileNo,
        pharmacy: pharmacy,
        message: message,
      };
      const response = await sendRequestToDeletePharamcy(payload);
      if (response.ok) {
        const allData = response?.data;
        if (allData?.status) {
          displayToast({
            type: "success",
            title: "Message send successfully",
          });
          setName("");
          setEmail("");
          setMobileNo("");
          setPharmacy("");
          setMessage("");
        } else {
          displayToast({
            type: "error",
            title: allData?.message,
          });
        }
      } else {
        displayToast({
          type: "error",
          title: "Something went wrong please try again.",
        });
      }
    }
  }, [ValidationForm, email, message, mobileNo, name, pharmacy]);

  return (
    <div
      style={{
        marginTop: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "80%",
          margin: "auto",
          paddingTop: "90px",
          paddingBottom: "40px",
          gap: "40px",
        }}
        className={classes["delete_container"]}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <ImageComponent
            src={
              "https://truetab.ai/wp-content/uploads/2022/03/image12-1024x902.png"
            }
            alt={"Image"}
            height={CalcWidthValue(507, width)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                color: "#2c234d",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
              }}
            >
              Request for Delete User From App
            </div>
            <form
              style={{
                gap: "20px",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
              }}
            >
              <CustomTextField
                fullWidth
                size="small"
                label="Enter Name"
                inputStyle={{
                  fontSize: CalcWidthValue(12, width),
                  lineHeight: width * 0.0068093385,
                }}
                lableStyle={{
                  color: "#333333",
                  fontSize: width * 0.007333333333,
                }}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              <CustomTextField
                fullWidth
                size="small"
                label="Enter your Email Id"
                inputStyle={{
                  fontSize: CalcWidthValue(12, width),
                  lineHeight: width * 0.0068093385,
                }}
                lableStyle={{
                  color: "#333333",
                  fontSize: width * 0.007333333333,
                }}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <CustomTextField
                fullWidth
                size="small"
                label="Enter Your Mobile Number"
                inputStyle={{
                  fontSize: CalcWidthValue(12, width),
                  lineHeight: width * 0.0068093385,
                }}
                lableStyle={{
                  color: "#333333",
                  fontSize: width * 0.007333333333,
                }}
                value={mobileNo}
                onChange={(event) => {
                  setMobileNo(event.target.value);
                }}
              />
              <CustomTextField
                fullWidth
                size="small"
                label="Pharmacy Name"
                inputStyle={{
                  fontSize: CalcWidthValue(12, width),
                  lineHeight: width * 0.0068093385,
                }}
                lableStyle={{
                  color: "#333333",
                  fontSize: width * 0.007333333333,
                }}
                value={pharmacy}
                onChange={(event) => {
                  setPharmacy(event.target.value);
                }}
              />
              <CustomTextField
                fullWidth
                multiline
                rows={3}
                size="small"
                label="Message"
                inputStyle={{
                  fontSize: CalcWidthValue(12, width),
                  lineHeight: width * 0.0068093385,
                }}
                lableStyle={{
                  color: "#333333",
                  fontSize: width * 0.007333333333,
                }}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
              />
              <div
                className={classes["delete_contact_button"]}
                onClick={() => {
                  onSubmitHandler();
                }}
              >
                Send Message
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserComponent;
