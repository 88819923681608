import React from "react";
import classes from "./HeaderComponent.module.css";
import ImageComponent from "../common/ImageComponent";
import NavBarComponent from "./NavBarComponent";
import { useNavigate } from "react-router";

const HeaderComponent = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        // height: CalcWidthValue(40, width),
        backgroundColor: "#5c2482",
        width: "100%",
        position: "fixed",
        padding: `21px 0px`,
        zIndex: "100",
      }}
    >
      <div
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "80%",
        }}
        className={classes["header-container"]}
      >
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          <ImageComponent
            style={{
              height: "26px",
              maxWidth: "90px",
            }}
            src="https://truetab.ai/wp-content/uploads/2022/03/logo-dark.png"
            alt="logo"
          />
        </div>
        <NavBarComponent />
      </div>
    </div>
  );
};

export default HeaderComponent;
